<template>
  <div class="sendrepair-detail">
    <div class="content">
      <div class="content__main">
        <div class="content__main-flag">
          <img :src="flagSvg" />
        </div>
        <div class="content__main-header">
          <div class="content__main-header-order">备用机租用单：{{ detail.code }}</div>
          <div class="content__main-header-name">
            <template>{{ detail.userParty }}_{{ detail.code }}</template>
            <span v-if="detail.status" :class="['c_tag', detail.status ? `status-${detail.status}` : '']">
              {{ detail.statusMsg }}
            </span>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="备用机信息" name="order">
          <Detail class="pane" :info="detail" :isHandle="true"></Detail>
        </el-tab-pane>
        <el-tab-pane label="日志动态" name="log">
          <Log ref="log" class="pane" :id="id"></Log>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="button-wrapper">
      <el-button v-if="spareSignForPermission && detail.status === 4" type="primary" @click="handleSignFor">
        签收
      </el-button>
      <el-button
        v-if="spareClosePermission && detail.status === 2 && detail.source === 1"
        type="primary"
        @click="openCloseDialog"
      >
        关闭
      </el-button>
      <el-button @click="handleBack">返回</el-button>
    </div>
    <SignForDialog ref="signForDialogRef" :type="2" @callback="handleCallback"></SignForDialog>
    <el-dialog title="确定关闭" :visible.sync="closeDialogVisible" width="600px">
      <el-form ref="closeForm" :model="closeForm">
        <el-form-item
          label="关闭原因"
          prop="closeReason"
          label-width="6em"
          :rules="[{ required: true, message: '关闭不能为空', trigger: 'blur' }]"
        >
          <el-input
            v-model="closeForm.closeReason"
            type="textarea"
            :rows="3"
            placeholder="请输入关闭原因"
            :maxlength="200"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Detail from './components/detail.vue';
import SignForDialog from '../components/signForDialog.vue';
import Log from '../components/log.vue';
import { sendRepairDetail, sendRepairSpareClose } from '@/api/logistics.js';
import mixin from '../mixin';

export default {
  mixins: [mixin],
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  components: {
    Detail,
    SignForDialog,
    Log,
  },
  data () {
    return {
      flagSvg: require('@/assets/document_checked.svg'),
      activeName: 'order',
      detail: {},
      closeDialogVisible: false,
      closeForm: {
        id: undefined,
        closeReason: '',
        operatorName: '',
      },
    };
  },
  methods: {
    handleCallback () {
      this.getDetail();
      this.$refs.log.init();
    },
    getDetail () {
      sendRepairDetail({
        id: this.id,
        findLogisticsTrackFlag: true,
        findRecordFlag: true,
      }).then(({ body }) => {
        this.detail = body || {};
      });
    },
    handleBack () {
      this.$router.back();
    },
    handleSignFor () {
      this.$refs.signForDialogRef.init(this.id);
    },
    openCloseDialog () {
      this.closeForm.id = this.id;
      this.closeForm.operatorName = this.userInfo.name;
      this.closeForm.closeReason = '';
      this.closeDialogVisible = true;

      this.$nextTick(() => {
        this.$refs.closeForm.resetFileds();
      });
    },
    handleClose () {
      this.$refs.closeForm.validate(async valid => {
        if (valid) {
          await sendRepairSpareClose(this.closeForm);

          this.$message.success('关闭成功');
          this.handleSearch();

          this.closeDialogVisible = false;
        }
      });
    },
  },
  created () {
    this.userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};
  },
  mounted () {
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.sendrepair-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content__main {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px 18px 0;

    .content__main-header {
      margin-left: 12px;

      .content__main-header-order {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }

      .content__main-header-name {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .status-2,
        .status-3 {
          background-color: #fefae0;
          color: #fab007;

          &:before {
            color: #fab007;
          }
        }

        .status-4 {
          background: #e2f3fe;
          color: #237ffa;

          &:before {
            color: #237ffa;
          }
        }

        .status-5 {
          background: #e5feeb;
          color: #14ca64;

          &:before {
            color: #14ca64;
          }
        }

        .status-8 {
          background-color: #f2f4f7;
          color: #1f2733;

          &:before {
            color: #1f2733;
          }
        }
      }
    }
  }

  /deep/ .el-tabs__header {
    background-color: #fff;
    padding: 0 18px 0;
    margin: 0;
  }

  .pane {
    height: calc(100vh - 316px);
    overflow: auto;
  }

  .button-wrapper {
    margin-top: 8px;
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
